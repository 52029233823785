import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import firebase from 'firebase';


//Akash Added Routes
import web from './ak-routes/web';
import store from '@/store'
import { db } from '@/main'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...web,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   console.log('Entered before');

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     console.log('Entered auth check');
//     console.log(store.getters);

//     if (!store.getters.isloggedIn) {
//       console.log('Entered redirect signin');

//       next({ path: '/signin' })
//     }
//     else {
//       //store.dispatch('fetchUser') // Line error
//       console.log('First Next');

//       return next();
//     }

//   } else {
//     console.log('Second Next');

//     return next();
//   }
// })

router.beforeEach((to, from, next) => {
  console.log(to["path"] ,"==", from["path"])
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      console.log(user)
      if(to["path"] == '/registration' || to["path"] == '/signin'){
        next({ name: 'Home' });
      }
    }
  });
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (to["path"] !== from["path"]) {
    if (requiresAuth) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // alert()
          // console.log(user.uid)
          store.dispatch("fetchUser", user);
          var uid = localStorage.getItem("userSessionId");
          db.collection('users').doc(uid).get()
          // db.collection('users').where('user_id', "==", user.uid).get()
            .then(data => {
              console.log(data.data())
              let status = data.data().status;
              let path = ['dashboard']
              console.log(to.meta);
              if (to.meta.roles.includes(status)) {
                next();
              }else{
                next({ name: 'Home' });
              }
              console.log("Logged in", user)
              next();

            });
        } else {
          console.log("Here", user) //this logs user = null on refresh 
          next('/signin')
        }
      });
    } else {
      // alert(document.referrer);
      localStorage.setItem("Return_url", document.referrer);
      next();
    }
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
